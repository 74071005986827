var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailInfo",
          attrs: { title: "아차사고 기본정보" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "아차사고번호",
                    value: _vm.nearAccidentInfo.iimNearAccidentNo,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "아차사고명",
                    value: _vm.nearAccidentInfo.iimNearAccidentName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "아차사고종류",
                    value: _vm.nearAccidentInfo.iimNearKindName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "발생일시",
                    value: _vm.nearAccidentInfo.occurrenceDt,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "계획 및 담당자의견" },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          editable: _vm.editable,
                          url: _vm.updateOpinionUrl,
                          isSubmit: _vm.isSaveOpin,
                          param: _vm.nearAccidentInfo,
                          mappingType: "PUT",
                          label: "계획/의견저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveNearAccident,
                          btnCallback: _vm.saveNearAccidentCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    rows: 6,
                    label: "단기계획",
                    name: "shortPlan",
                  },
                  model: {
                    value: _vm.nearAccidentInfo.shortPlan,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccidentInfo, "shortPlan", $$v)
                    },
                    expression: "nearAccidentInfo.shortPlan",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    rows: 6,
                    label: "중장기 계획",
                    name: "longPlan",
                  },
                  model: {
                    value: _vm.nearAccidentInfo.longPlan,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccidentInfo, "longPlan", $$v)
                    },
                    expression: "nearAccidentInfo.longPlan",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    rows: 6,
                    label: "담당자 의견",
                    name: "managerOpinion",
                  },
                  model: {
                    value: _vm.nearAccidentInfo.managerOpinion,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccidentInfo, "managerOpinion", $$v)
                    },
                    expression: "nearAccidentInfo.managerOpinion",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "tableImpr",
          attrs: {
            title: "원인 및 재발방지 목록",
            columns: _vm.gridImpr.columns,
            data: _vm.nearAccidentInfo.nearAccidentPreventionModels,
            merge: _vm.gridImpr.merge,
            selection: "multiple",
            rowKey: "iimNearCausesId",
            usePaging: false,
            isExcelDown: false,
            autoFullHeight: true,
            filtering: false,
            columnSetting: false,
            editable: _vm.editable && !_vm.disabled,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "customCol"
                    ? [
                        _c(_vm.imprComponent, {
                          tag: "component",
                          attrs: {
                            col: col,
                            props: props,
                            inputEditable: _vm.editable && !_vm.disabled,
                            isImmShow: true,
                            requestContentsCols: _vm.requestContentsCols,
                            tableKey: "iimNearCausesId",
                            ibmTaskTypeCd: "ITT0000045",
                            ibmTaskUnderTypeCd: "ITTU000070",
                          },
                          on: { imprChange: _vm.imprChange },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          label: "추가",
                          showLoading: false,
                          icon: "add",
                        },
                        on: { btnClicked: _vm.add },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.nearAccidentInfo.nearAccidentPreventionModels
                            .length > 0,
                        expression:
                          "editable && !disabled && nearAccidentInfo.nearAccidentPreventionModels.length > 0",
                      },
                    ],
                    attrs: {
                      url: _vm.updateCauseUrl,
                      isSubmit: _vm.isSaveCause,
                      param: _vm.nearAccidentInfo.nearAccidentPreventionModels,
                      mappingType: "PUT",
                      label: "저장",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.saveNearImpr,
                      btnCallback: _vm.saveNearImprCallback,
                    },
                  }),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.nearAccidentInfo.nearAccidentPreventionModels.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          label: "삭제",
                          showLoading: false,
                          icon: "remove",
                        },
                        on: { btnClicked: _vm.removeImpr },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }